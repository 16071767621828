import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Webchat from '../../components/webchat/Webchat';
import PublicRoute from './PublicRoute';

const MainRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <Webchat />
          </PublicRoute>
        }
      />

    </Routes>
  );
};

export default MainRoutes;
