import React from 'react';
import { Box, Text, VStack, HStack, Link } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box as="footer" bg="#0F172A" color="white" pt={8} ml={'-2'} mr={'-2'}>
      <VStack spacing={8} align="stretch" maxW="90rem" mx="auto" px={4}>
        <HStack
          justifyContent="space-between"
          spacing={{ base: 8, md: 16 }}
          alignItems="start" // Ensures all items align to the top
          flexDirection={{ base: 'column', md: 'row' }}
        >
          {/* About Section */}
          <Box flex="1" maxW="400px" mb={{ base: 8, md: 0 }}>
            <Text fontWeight="bold" fontSize="lg">
              About
            </Text>
            <Text fontSize="sm" lineHeight="tall">
              Hairadvisor.ai is owned and operated by Stephen Marinaro,
              “TheSalonGuy.” Hairadvisor is your all-in-one source for
              hair-related questions and answers for consumers and hair
              professionals. Follow TheSalonGuy on all social platforms under
              @thesalonguy. For feedback or questions, email{' '}
              <Link href="mailto:stephen@thesalonguy.com" color="blue.300">
                stephen@thesalonguy.com
              </Link>
              .
            </Text>
            <Text fontSize="sm" lineHeight="tall" fontWeight="bold">
              This application has a daily use limit. Once the daily limit has
              been reached, the application will be unavailable to users until
              midnight EST.
            </Text>
            
          </Box>
          {/* Disclaimer Section */}
          <Box flex="1" maxW="400px" mb={{ base: 8, md: 0 }}>
            <Text fontWeight="bold" fontSize="lg">
              Disclaimer
            </Text>
            <Text fontSize="sm" lineHeight="tall">
              Hairadvisor.ai is the property of and owned by Stephen Marinaro,
              “TheSalonGuy,” a legal entity of Stephen Gabriel Designs, LLC. We
              do not guarantee nor take responsibility for any answers generated
              by the Hairadvisor.ai engine. We are not responsible for any
              results or outcomes based on answers, images, or locations that
              the AI engine provides as a response. Hairadvisor.ai is the
              copyright of TheSalonGuy and may not be duplicated. You will be
              held liable for any copyright infringement or misuse of the site.
            </Text>
          </Box>
          {/* FAQ Section */}
          <Box flex="1" maxW="400px" mb={{ base: 8, md: 0 }}>
            <Text fontWeight="bold" fontSize="lg">
              FAQ
            </Text>
            <Text fontSize="sm" lineHeight="tall">
              Hairadvisor is powered by AI technology and was created to answer
              hair-related questions. You can ask it to generate high-resolution
              images of hairstyles and colors, locate local salons, get hair
              tips, advice, and more. Be specific with your questions, and keep
              in mind that results may vary or may not be fully accurate.
            </Text>
          </Box>
        </HStack>
        
      </VStack>
      <Text fontSize="sm" lineHeight="tall" textAlign="center" borderTop="1px" mt={8} py={2}>
              Help Hairadvisor grow by contributing to the application. To
              donate, please use the link provided at the top of the page.
            </Text>
    </Box>
  );
};

export default Footer;
